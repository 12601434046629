* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
::-ms-overflow {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* colors */

:root {
  /* primary colors */
  --black: #000;
  --white: #fff;
--darkGreen:rgb(0, 255, 57);
--floralWhite:#FFFFF5;

  --eerie-black: rgba(26, 26, 26, 1);
  --errie-dak-black: rgb(26, 26, 26);
  --ivory: rgba(255, 255, 245, 1);
}

/* fonts */

@font-face {
  font-family: 'Custom-Light';
  src: url('assets/fonts/font-795392e5-498d-4467-823f-75eee0b11d6b.woff');
  font-weight: 300;
}

@font-face {
  font-family: 'Custom-Light-Italic';
  src: url('assets/fonts/font-d731b91d-0ba8-4b55-a4bf-ea58e5615dc4.woff');
  font-weight: 300;
}

@font-face {
  font-family: 'Custom-Medium';
  src: url('assets/fonts/font-afd120db-48f0-4aed-bd10-8a396399995a.woff');
  font-weight: 400;
}

@font-face {
  font-family: 'Custom-Medium-Italic';
  src: url('assets/fonts/font-07020163-851f-4b35-a2a7-4e3c67f5915c.woff');
  font-weight: 400;
}

/* @font-face {
  font-family: 'Custom-Regular';
  src: url();
} */

@font-face {
  font-family: 'Custom-SemiBold';
  src: url('assets/fonts/font-585d74be-845c-4889-8f63-f17df8bab958.woff');
  font-weight: 600;
}

@font-face {
  font-family: 'Custom-SemiBold-Italic';
  src: url('assets/fonts/font-e02f9cd2-655d-445a-8900-3f85381d3eaf.woff');
  font-weight: 600;
}

@font-face {
  font-family: 'Custom-Bold';
  src: url('assets/fonts/font-527cadb7-b7f1-4493-958e-c3391424c65f.woff');
  font-weight: 700;
}

@font-face {
  font-family: 'Custom-Bold-Italic';
  src: url('assets/fonts/font-707555cd-1214-4fdf-9905-cb11d61275de.woff');
  font-weight: 700;
}


