/* .headerContainerStyle {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 16px;
  position: sticky;
  top: 0;
  z-index: 1;
  max-width: 1440px;
  margin-inline: auto;
}
.leftSectionStyle,
.rightSectionStyle {
  display: flex;
  align-items: center;
  gap: 32px;
}
.rightSectionStyle {
  gap: 24px;
}
.mainLogoImgStyle {
  width: 65px;
  height: 21px;
  cursor: pointer;
}
.registerMonthStyle {
  display: flex;
  flex-direction: column;
}
.registerMonthTitleStyle,
.registerAddressTitleStyle {
  color: var(--eerie-black);
  font-family: 'Custom-Medium', sans-serif;
  font-size: 7px;
  font-style: normal;
  font-weight: 400;
}
.buttonContainerStyle {
  height: 13px;
  padding: 0px;
  width: fit-content;
  padding: 0px 8px;
  border-radius: 30px;
}
.buttonTitleStyle {
  font-size: 7px;
}
.rightTabSectionStyle{
  display: none;
}
.rightMenuSectionStyle{
  display: none;
}


@media screen and (max-width:897px) {
  .headerContainerStyle {
   
    padding: 16px 12px;
  
  }
  
  .rightSectionStyle{
    display: none;
  }
  .rightTabSectionStyle{
    display: block;
    position: relative;
    
 
  }
.rightMenuSectionStyle{
  position: absolute;
  height: 100vh;
  display: block;
  background-color: var(--white);
  z-index: 5;
  width: 100%;
  inset: 0;
  padding: 24px;
}
.customMainIconStyle{
  width: fit-content;
}
.menuItemsStyle{
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin: auto;
  align-items: center;
  overflow-y: scroll;
}
.menuTitleStyle{
  color: var(--eerie-black);
  font-family: 'Custom-SemiBold', sans-serif;
  font-size: 30px;
  line-height: 38px;
  font-style: normal;
  cursor: pointer;
  border-bottom: 1px solid var(--black);
  width: fit-content;

}
.menuItemsStyle:hover{
border-bottom: none
}
}


@media screen and (max-width:380px) {
  
.rightMenuSectionStyle{
  padding: 16px;
}

.menuItemsStyle{
  gap: 24px;
  margin: 0;
  margin-right: auto;
  align-items: flex-start;
}
.menuTitleStyle{
  font-size: 20px;
  line-height: 24px;
}
}

 */

.headerContainerStyle {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10px 0px;
  position: fixed;
  top: 0;
  z-index: 2;
  /* max-width: 1440px;
  margin-inline: auto; */
}

.leftSectionStyle,
.rightSectionStyle {
  display: flex;
  align-items: center;
  gap: 24px;
}

.rightSectionStyle {
  gap: 16px;
}

.mainLogoImgStyle {
  width: 93.89px;
  height: 30.33px;
  cursor: pointer;
}

.registerMonthStyle {
  display: flex;
  flex-direction: column;
}

.registerMonthTitleStyle,
.registerAddressTitleStyle {
  color: var(--eerie-black);
  font-family: 'Custom-Medium', sans-serif;
  font-size: 10.11px;
  font-style: normal;
  font-weight: 400;
}

.buttonContainerStyle {
  height: 18.78px;
  padding: 0px 18.56px;
  width: fit-content;
  border-radius: 43.33px;
}

.buttonTitleStyle {
  font-size: 10.11px;
}

.rightTabSectionStyle {
  display: none;
}

.rightMenuSectionStyle {
  display: none;
}

@media screen and (max-width: 1139px) {
  .headerContainerStyle {
    padding: 16px 10px 0px;
  }
  .mainLogoImgStyle {
    width: 71.79px;
    height: 23.19px;
  }
  .rightSectionStyle {
    gap: 12px;
  }
  .buttonContainerStyle {
    padding: 0px 12px;
    height: 16px;
  }
}

@media screen and (max-width: 897px) {
  .mainLogoImgStyle {
    width: 117px;
    height: 39px;
  }
  .headerContainerStyle {
    padding: 17.7px 13.3px;
  }
  .registerMonthStyle {
    display: none;
  }

  .rightSectionStyle {
    display: none;
  }

  .rightTabSectionStyle {
    display: block;
    position: relative;
  }

  .rightMenuSectionStyle {
    position: absolute;
    height: 100vh;
    display: block;
    background-color: var(--floralWhite);
    z-index: 5;
    width: 100%;
    inset: 0;
    padding: 26.6px;
  }

  .customMainIconStyle {
    width: fit-content;
  }

  .menuItemsStyle {
    display: flex;
    flex-direction: column;
    gap: 35.5px;
    margin: auto;
    align-items: center;
    overflow-y: scroll;
  }

  .menuTitleStyle {
    color: var(--eerie-black);
    font-family: 'Custom-SemiBold', sans-serif;
    font-size: 33.3px;
    line-height: 42.2px;
    font-style: normal;
    cursor: pointer;
    border-bottom: 1px solid var(--black);
    width: fit-content;
  }

  .menuItemsStyle:hover {
    border-bottom: none;
  }
}

@media screen and (max-width: 380px) {
  .rightMenuSectionStyle {
    padding: 17.7px;
  }

  .menuItemsStyle {
    gap: 24px;
    margin: 0;
    margin-right: auto;
    align-items: flex-start;
  }

  .menuTitleStyle {
    font-size: 28.89px;
    line-height: 34.67px;
  }
}
