/* .footerContainerStyle {
  width: 100%;
  padding: 24px;
  background: var(--errie-dak-black);
  display: flex;
  justify-content: space-between;

}
.leftSectionStyle,
.privacyPolicyStyle {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.leftSectionStyle{
  flex: 1;
}
.privacyPolicyStyle {
  gap: 0px;
}
.priticalInformationTitleStyle,
.privacyPolicyTitleStyle,
.socialMediaTitleStyle,
.infoTitleStyle,
.contactTitleStyle,
.contactInfoTitleStyle {
  color: var(--ivory);
  font-size: 7px;
  letter-spacing: -0.1px;
  line-height: 8px;
  font-family: 'Custom-Medium', sans-serif;
}
.contactInfoTitleStyle:hover {
  text-decoration: underline;
  cursor: pointer;
}
.socialAndInfoStyle {
  display: flex;
  gap: 60px;
}
.socialMediaStyle,
.infoStyle,
.locationStyle,
.contactStyle {
  display: flex;
  flex-direction: column;
}
.infoStyle {
  gap: 16px;
}
.rightSectionStyle{
  display: flex;
  flex-direction: column;
  gap: 150px;
  flex: 1;
}
.footerTopUrlImgStyle{
  width: 100px;
  height: 24px;
  cursor: pointer;
}
.footerBottomUrlImgStyle{
  width: 100px;
  height: 34px;
  cursor: pointer;
}
.rightTopImgStyle{
  display: flex;
  gap: 32px;
  justify-content: flex-end;
}
.rightBottomImgStyle{
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-left: auto;
}
.bottomImgIndexingStyle{
  display: flex;
  gap: 24px;
}
.customImgStyle{
  object-fit: contain;
}

@media screen and (max-width:897px){
  
} 
 */

.footerContainerStyle {
  width: 100%;
  padding: 34.67px;
  background: var(--errie-dak-black);
  display: flex;
  justify-content: space-between;
}

.mobilePrivacyAndContactStyle {
  display: flex;
  gap: 32px;
}

.leftSectionStyle,
.privacyPolicyStyle {
  display: flex;
  flex-direction: column;
  gap: 46.22px;
}

.leftSectionStyle {
  flex: 1;
}

.privacyPolicyStyle {
  gap: 0px;
}

.priticalInformationTitleStyle,
.privacyPolicyTitleStyle,
.socialMediaTitleStyle,
.infoTitleStyle,
.contactTitleStyle,
.contactInfoTitleStyle {
  color: var(--ivory);
  font-size: 10.11px;
  letter-spacing: -0.1px;
  line-height: 11.56px;
  font-family: 'Custom-Medium', sans-serif;
}

.contactInfoTitleStyle:hover {
  text-decoration: underline;
  cursor: pointer;
}

.socialAndInfoStyle {
  display: flex;
  gap: 109.77px;
}

.socialMediaStyle,
.infoStyle,
.locationStyle,
.contactStyle {
  display: flex;
  flex-direction: column;
}

.infoStyle {
  gap: 16px;
}

.rightSectionStyle {
  display: flex;
  flex-direction: column;
  gap: 216.67px;
  flex: 1;
}

.footerTopUrlImgStyle {
  /* width: 208px; */
  width: 100%;
  max-width: 208px;
  height: 37.55px;
  cursor: pointer;
}

.footerBottomUrlImgStyle {
  /* width: 208px; */
  width: 100%;
  max-width: 208px;
  height: 49.11px;
  cursor: pointer;
}

.rightTopImgStyle {
  display: flex;
  gap: 46.22px;
  justify-content: flex-end;
}

.rightBottomImgStyle {
  display: flex;
  flex-direction: column;
  gap: 46.22px;
  margin-left: auto;
}

.bottomImgIndexingStyle {
  display: flex;
  gap: 34.67px;
}

.customImgStyle {
  object-fit: contain;
}
.mobileInfoSectionStyle,
.mobileSocialMediaStyle,
.rightBottomMobileImgStyle,
.mobileBorderStyle {
  display: none;
}

@media screen and (max-width: 1320px) {
  .footerContainerStyle {
    padding: 24.67px;
  }

  .footerTopUrlImgStyle {
    max-width: 150px;
    height: 27.55px;
  }
  .rightTopImgStyle {
    gap: 32.22px;
  }
  .rightBottomImgStyle {
    gap: 32.22px;
  }
  .footerBottomUrlImgStyle {
    max-width: 150px;
    height: 39.11px;
  }
}

@media screen and (max-width: 1139px) {
  .footerContainerStyle {
    padding: 16px 20px 16px 8px;
  }
  .leftSectionStyle {
    gap: 32px;
  }
  .footerTopUrlImgStyle {
    max-width: 82px;
    height: 27.55px;
  }

  .footerBottomUrlImgStyle {
    max-width: 82px;
    height: 39.11px;
  }
}

@media screen and (max-width: 898px) {
  .leftSectionStyle {
    gap: 32px;
  }
  .socialAndInfoStyle {
    gap: 70px;
  }

  .rightSectionStyle {
    gap: 160px;
  }
  .footerTopUrlImgStyle {
    max-width: 60px;
    height: 17.55px;
  }

  .footerBottomUrlImgStyle {
    max-width: 60px;
    height: 26.11px;
  }
  /* .priticalInformationTitleStyle,
  .privacyPolicyTitleStyle,
  .socialMediaTitleStyle,
  .infoTitleStyle,
  .contactTitleStyle,
  .contactInfoTitleStyle {
    font-size: 7px;
    line-height: 9px;
  } */
}

@media screen and (max-width: 550px) {
  .footerContainerStyle {
    padding: 30px 16px;
    flex-direction: column;
    gap: 86px;
  }
  .mobileInfoSectionStyle {
    display: block;
  }
  .mobileInfoStyle {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .rightSectionStyle {
    gap: 40px;
  }
  .rightTopImgStyle {
    justify-content: flex-start;
  }
  .rightBottomImgStyle {
    margin-left: 0;
  }
  .infoStyle,
  .socialMediaStyle,
  .mobileSocialMediaStyle {
    display: none;
  }
  .mobileSocialMediaStyle {
    display: block;
  }
  .mobilePrivacyPolicyStyle {
    display: flex;
    flex-direction: column;
    gap: 14px;
  }
  .leftSectionStyle {
    gap: 0px;
  }
  .footerTopUrlImgStyle {
    width: 140px;
    max-width: 100%;
    height: 43.48px;
  }

  .footerBottomUrlImgStyle {
    max-width: 140px;
    height: 50px;
  }
  .rightBottomImgStyle {
    display: none;
  }
  .rightTopImgStyle {
    gap: 18px;
    flex-wrap: wrap;
    justify-content: center;
  }
  .rightBottomMobileImgStyle {
    display: flex;

    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  .mobileBorderStyle {
    display: block;
    border: 1px solid beige;
  }
}

@media screen and (max-width: 379px) {
  .footerContainerStyle {
    padding: 24px 14px;
    gap: 36px;
  }
  .rightSectionStyle {
    gap: 24px;
  }
  .footerTopUrlImgStyle {
    max-width: 80px;
  }
  .footerBottomUrlImgStyle {
    max-width: 80px;
  }
}
