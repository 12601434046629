.layoutContainerStyle {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.ODaysUpdateStyle,.odaysUpdateAnimateStyle{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  position: sticky;
  left: 24px;
  bottom: 24px;
  background-color: beige;
  border-radius: 50%;
  padding: 20px;
  z-index: 10;
  cursor: pointer;
}

.odaysUpdateAnimateStyle{
  animation: animate 0.5s ease-in-out;
}

@keyframes animate {
  0%{
    transform: translateY(-100px);
  }
  100%{
    transform: translateY(0px);
  }
}
